// import React, { useState, useEffect } from 'react';
//changed
import React from 'react';
import Term from './components/Term.js'

export default function App() {
  return (
    <div className='container'>
      <Term />
    </div>
  )
}


