/* eslint-disable import/no-anonymous-default-export */
// import getcat from "../../utils/cat"
// import getnp from "../../utils/spotify"
export default {
    commands: {
        echo: {
            description: 'Prints the given text to the console',
            usage: 'echo <text>',
            fn: (...args) => args.join(" ")
        },
        // linux: {
        //     description: 'Get a random linux image.',
        //     usage: 'linux',
        //     fn: async () => {
        //         // eslint-disable-next-line no-undef
        //         const url = await getRandomLinuxImage()
        //         window.open(url, '_blank')
        //         return "fetching cat...\ncat fetched successfully!"
        //     }
        // },
        about: {
            description: 'About Me',
            usage: 'about',
            fn: () => {
                return "About Me.\n---Hi! My name is Akrur Neupane and I am a Devops Engineer.\n---Currently studying BCA from Tribhuvan University.\n---I have skills like Linux, AWS, CI/CD, Ansible, Jenkins etc.\n---I have a passion of exploring and learning new things\n"
            }
        },
        medium: {
            description: 'Opens my Medium Blogs',
            usage: 'medium',
            fn: () => {
                window.open('https://medium.com/@neupaneakrur11', '_blank')
                return "opening medium blogs..."
            }
        },
        github: {
            description: 'Opens my GitHub Profile',
            usage: 'twitter',
            fn: () => {
                window.open('https://github.com/neupaneakrur', '_blank')
                return "opening github account..."
            }
        },
        linkedin: {
            description: 'Opens my LinkedIn Account',
            usage: 'twitter',
            fn: () => {
                window.open('https://www.linkedin.com/in/neupaneakrur/', '_blank')
                return "opening LinkedIn account..."
            }
        },
        languages: {
            description: 'Languages I know',
            usage: 'languages',
            fn: () => {
                return `
                    These are the languages I know\n---\n
                    Nepali           - 100%
                    English          - 80%
                    Hindi            - 70%
                    javascript       - 60%
                    python           - 40%
                    YML              - 40% \n---\n
                `
            }
        },
        skills: {
            description: 'Skills I have',
            usage: 'skills',
            fn: () => {
                return `
                    These are the skills I have.\n---\n
                    Linux 
                    Docker 
                    Git             
                    AWS   
                    CI/CD           
                    Python & MERN    \n---\n
                `
            }
        },
        projects: {
            description: 'Projects I have worked on',
            usage: 'projects',
            fn: () => {
                return `
                    Cool projects I have worked on.\n---\n
                    'docker-image'                    | 'Build docker image and run it by using docker compose'                     | 'docker, docker compose'
                    'github actions'                  | 'used github actions to deploy project'                                     | 'github action, yml'
                    'AWS Arctitecture Design'         | 'Designed and implemented Full stack application in AWS'                    | 'VPC, Subnet, ASG, EC2, ALB'
                    'Docker container on AWS'         | 'Added docker container on AWS ECR and run it using Apprunner'              | 'ECR, Apprunner, Cloud9, IAM, S3'
                    'Ansible AWX'                     | 'Installed and used Ansible AWX and managed Infrastructure'                 | 'IaC, AWX, Playbooks, Jobs'\n---\n
                `
            }
        },
        job: {
            description: 'Details about my current job',
            usage: 'job',
            fn: () => {
                return `
                    Job: DevOps engineer\n
                    Company: Fintech\n
                    Started  : Consolas
                `
            }
        }
        // repo: {
        //     description: "Opens this website's github repository",
        //     usage: 'repo',
        //     fn: () => {
        //         window.open("https://github.com/asrvd/AshTerm", '_blank')
        //         return "opening repository..."
        //     }
        // }
        // spotify: {
        //     description: 'Get info about my recently played song.',
        //     usage: 'spotify',
        //     fn: async () => {
        //         const item = await getnp()
        //         return `
        //             Now Playing/Recently Played\n
        //             ---\n
        //             Song: ${item.song}\n
        //             Artist: ${item.artist}\n---\n
        //         `
        //     }
        // }
    },
    overwrites:{
        help: {
            description: 'List all available commands',
            usage: 'help',
        },
        ls: {
            description: 'List files in the current directory',
            usage: 'ls',
        },
        mkdir: {
            description: 'Make a directory',
            usage: 'mkdir <directory>',
        },
        clear: {
            description: 'Clears the terminal',
            usage: 'clear'
        }
    }
}
